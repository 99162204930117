import moment from 'oo-moment';
import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import Helmet from 'react-helmet';

import { routes, path } from 'inkp-routes/public';
import { SHIPPING_SPEED_ENUM } from 'inkp-order-sdk/types.g';

// Components
import PrimaryButton from 'inkp-components/dist/Components/PrimaryButton';
import CommentSlider from 'inkp-components/dist/Components/CommentSlider';
import Alert from 'inkp-components/dist/Components/Alert';
import Modal from 'inkp-components/dist/Components/Modal';
import { getFreeShipDate, getFastestShipDate } from '../../util/shippingDates';

// GTM helpers
import GTM from '../../util/gtm';
import { GTMTypes } from '../../interfaces/GTM';

interface Props {}

interface HomePageProps extends RouteComponentProps<Props> {}

const sampleClassTitle: any = {
  color: '#FE5454',
  fontSize: 24,
  fontWeight: 800,
  lineHeight: '36px',
};

const sampleClassSubTitle: any = {
  color: '#1F2245',
  fontSize: '16px',
  fontWeight: 'bold',
  lineHeight: '24px',
  marginTop: '0.3rem',
};

const COMMENTS = [
  {
    body:
      "The shirts are amazing!!!! Excellently soft quality, with prints that I know aren't coming off anytime soon. What a great experience, I will have to shop here again.",
    author: 'Aaron M',
    location: 'Chandler, AZ',
    title:
      "The shirts are amazing!!!! Excellently soft quality, with prints that I know aren't coming off anytime soon. What a great experience, I will have to shop here again.",
  },
  {
    body:
      'Last minute I needed these shirts and after working with your team for sweatshirts I knew this would be the place to go. Shirts came out great and I will be using you again in the future.',
    author: 'Elena P',
    location: 'Hot Springs, AR',
    title:
      'Last minute I needed these shirts and after working with your team for sweatshirts I knew this would be the place to go. Shirts came out great and I will be using you again in the future.',
  },
  {
    body: 'The shirts looked beautiful and arrived earlier than expected; we are so very happy with our order!',
    author: 'Kris R',
    location: 'Sacramento, CA',
    title: 'The shirts looked beautiful and arrived earlier than expected; we are so very happy with our order!',
  },
  {
    body:
      'It has been fantastic, our shirts arrived a week early and look amazing! The price was great and I will definitely use Inkpop again!',
    author: 'Jaclyn H',
    location: 'Salt Lake , UT',
    title:
      'It has been fantastic, our shirts arrived a week early and look amazing! The price was great and I will definitely use Inkpop again!',
  },
  {
    body: 'My favorite place to order shirts. Be price, best quality and best service!',
    author: 'Peter L',
    location: 'Phoenix, AZ ',
    title: 'My favorite place to order shirts. Be price, best quality and best service!',
  },
  {
    body: 'Great shirts at a great price and great service. Needless to say, I am happy with Inkpop! :)',
    author: 'John K',
    location: 'Seattle, WA',
    title: 'Great shirts at a great price and great service. Needless to say, I am happy with Inkpop! :)',
  },
  {
    body:
      'The T-shirts came out so perfect, I had one color on white. But there was a lot of detail. Absolutely flawless. Will use them again',
    author: 'Thomas K',
    location: 'Columbus, OH',
    title:
      'The T-shirts came out so perfect, I had one color on white. But there was a lot of detail. Absolutely flawless. Will use them again',
  },
];

const DELIVER_BY_DATE_RUSH = getFastestShipDate();
const DELIVER_BY_DATE_FREE = getFreeShipDate();
const LIGHTNING_LINK_PROPS = {
  pathname: '/products',
  search: `?filters[speeds][0]=${SHIPPING_SPEED_ENUM.THREE_DAY}&filters[colors][0]=black`,
};

interface HomePageState {
  showCOVID19Alert: boolean;
}

export default class HomePage extends React.Component<HomePageProps, HomePageState> {
  constructor(props: HomePageProps) {
    super(props);
    this.state = {
      showCOVID19Alert: true,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      GTM.push(GTMTypes.USER);
    }, 0);
  }

  onCloseCOVIDAlert = () => () => {
    return this.setState({
      showCOVID19Alert: false,
    });
  };

  render() {
    const { showCOVID19Alert } = this.state;
    return (
      <div>
        <Helmet>
          <title>Custom T-Shirt Design - FREE Shipping & No Minimums</title>
          <meta
            name="description"
            content="Choose Inkpop for your custom shirt printing needs. Our Happiness Guarantee ensures your satisfaction with both print quality and on-time delivery"
          />
        </Helmet>
        <div className="w-full bgc-white bc-navy-200 bwb-1">
          <style jsx>{`
            .hero-section {
              background-image: url(https://inkp-production.s3-us-west-2.amazonaws.com/public/app/homepage/blue-waves.png);
              background-position: 90% top;
              background-repeat: no-repeat;
            }
            .hero-slide-title {
              line-height: 45px;
            }
            .hero-slide-subtitle {
              line-height: 36px;
              font-weight: 500;
            }
            .hero-slide-image > img {
              width: 375px;
              height: 305px;
              background-image: url(https://inkp-production.s3-us-west-2.amazonaws.com/public/app/homepage/quarantine-covid-19-hero-image-1.png);
              background-position: top center;
              background-repeat: no-repeat;
              background-size: contain;
            }

            @media (min-width: 1024px) {
              .hero-section {
                height: 623px;
                background-position: center center;
                background-size: cover;
              }
              .hero-slide-title {
                line-height: 60px;
              }
              .hero-slide-image > img {
                width: 765px;
                height: 623px;
                max-width: unset;
              }
            }

            @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
              .hero-slide-image > img {
                background-image: url(https://inkp-production.s3-us-west-2.amazonaws.com/public/app/homepage/quarantine-covid-19-hero-image-1%402x.png);
              }
            }
          `}</style>
          <div className="hero-section">
            <div className="hero-slider-container lg:w-container lg:mx-auto lg:h-full">
              <div className="hero-slide lg:flex lg:h-full">
                <div className="hero-slide-copy px-1 pt-3 pb-1p5 lg:w-5/12 lg:flex lg:flex-col lg:justify-center lg:p-0">
                  <p className="hero-slide-title color-primary fw-extra-bold fs-2xl m-0 mb-1 lg:mb-1p5 lg:fs-3xl lg:fw-extra-bold">
                    Quarantine Crew <br />
                    T-shirts
                  </p>
                  <p className="hero-slide-subtitle color-navy fs-xl mb-2 lg:mb-3">
                    Spread the Message with Custom T-shirts
                  </p>
                  <Link
                    to={routes.app.designTool}
                    className="d-ib py-p75 px-1p5 fs-xl color-white br-full bgc-primary bgc-primary-600:hover fw-bold capitalize ta-center lg:w-2/3"
                  >
                    start designing
                  </Link>
                </div>
                <div className="hero-slide-image lg:w-7/12 lg:h-full">
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
                    alt="Family with custom printed t-shirts with COVID-19 preventive measures."
                    className="d-b"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* END Top background */}

        {/* START Delivery Speeds */}
        <div className="w-full bc-navy-200 lg:bwb-1 py-1">
          <div className="lg:w-container mx-auto">
            <div className="flex flex-col items-center lg:items-start lg:flex-row">
              <div className="my-p75 lg:my-0 lg:w-1/3">
                <div className="flex flex-col lg:flex-row items-center lg:items-start justify-start">
                  <Link to={LIGHTNING_LINK_PROPS} className="flex">
                    <div
                      className="flex items-center justify-center bgc-primary-50 br-full mb-p5"
                      style={{ width: '44px', height: '44px' }}
                    >
                      <i className="mdi mdi-flash color-primary fs-xl" />
                    </div>
                    <div className="flex flex-col items-center lg:items-start ml-1">
                      <div className="fs-md fw-extra-bold color-primary">Lightning Delivery</div>
                      <div className="fs-sm fw-normal color-navy">
                        Guaranteed by{' '}
                        <span className="color-primary">{moment(DELIVER_BY_DATE_RUSH).format('ddd, MMM D')}</span>
                      </div>
                      <div className="fs-sm fw-normal color-navy-500">Order select products by 12pm ET</div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="my-p75 lg:my-0 lg:w-1/3">
                <div className="flex flex-col lg:flex-row items-center lg:items-start justify-center">
                  <div
                    className="flex items-center justify-center bgc-blue-50 br-full mb-p5"
                    style={{ width: '44px', height: '44px' }}
                  >
                    <i className="mdi mdi-truck-fast color-blue fs-xl" style={{ transform: 'scaleX(-1)' }} />
                  </div>
                  <div className="flex flex-col items-center lg:items-start ml-1">
                    <div className="fs-md fw-extra-bold color-blue">Free Shipping</div>
                    <div className="fs-sm">
                      Guaranteed by{' '}
                      <span className="color-blue">{moment(DELIVER_BY_DATE_FREE).format('ddd, MMM D')}</span>
                    </div>
                    <div className="fs-sm color-navy-500">On any products & any quantity</div>
                  </div>
                </div>
              </div>
              <div className="my-p75 lg:my-0 lg:w-1/3">
                <div className="flex flex-col lg:flex-row items-center lg:items-start justify-end">
                  <div
                    className="flex items-center justify-center bgc-green-50 br-full mb-p5"
                    style={{ width: '44px', height: '44px' }}
                  >
                    <i className="mdi mdi-tshirt-crew color-green fs-xl" />
                  </div>
                  <div className="flex flex-col items-center lg:items-start ml-1">
                    <div className="fs-md fw-extra-bold color-green">1,500,000+</div>
                    <div className="fs-sm">T-Shirts printed this year</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* END Delivery Speeds */}

        {/* START CENTER Container with products copy */}
        <div className="lg:w-container mx-auto mt-1 lg:mt-3">
          <div className="d-b bgc-white w-full ta-center">
            <div className="fs-2xl fw-extra-bold mb-1 lg:mb-1p5">Put Your Design on (Almost) Anything</div>
            {/* START Desktop */}
            <p className="d-n lg:d-b color-navy" style={{ fontSize: 24, fontWeight: 500, lineHeight: '36px' }}>
              Any garment, style, deadline, or quantity. <br />
              Your design, our expertise, your savings.
            </p>
            {/* END Desktop */}
            {/* START Mobile */}
            <p className="d-b lg:d-n color-navy" style={{ fontSize: 24, fontWeight: 500, lineHeight: '36px' }}>
              Any garment, style, deadline, or quantity. Your design, our expertise, your savings.
            </p>
            {/* END Mobile */}
          </div>
          {/* END CENTER Container with products copy */}

          {/* START CENTER Container with tags */}
          <div className="flex flex-wrap bgc-white w-full ta-center px-p5 mt-2 lg:mt-3">
            <div className={`lg:w-1/4 w-1/2`}>
              <div className="br bgc-gray-50 py-1 my-p5 mx-p5 ta-center">
                <Link to={path(routes.app.product.category, { category: 't-shirts' })}>
                  <img className="mb-p5" src="https://inkp-production.32pt.com/public/app/T-Shirt.jpg" alt="T-shirts" />
                  <span className="mb-p5" style={sampleClassTitle}>
                    T-Shirts
                  </span>
                  <span className="d-b" style={sampleClassSubTitle}>
                    Shop All <i className="d-ib mdi mdi-chevron-right fs-lg align-middle" />
                  </span>
                </Link>
              </div>
            </div>
            <div className={`lg:w-1/4 w-1/2`}>
              <div className="br bgc-gray-50 py-1 my-p5 mx-p5 ta-center">
                <Link to={path(routes.app.product.category, { category: 'sweatshirts' })}>
                  <img
                    className="mb-p5"
                    src="https://inkp-production.32pt.com/public/app/Sweatshirt.jpg"
                    alt="Sweatshirts"
                  />
                  <span className="mb-p5" style={sampleClassTitle}>
                    Sweatshirts
                  </span>
                  <span className="d-b" style={sampleClassSubTitle}>
                    Shop All <i className="d-ib mdi mdi-chevron-right fs-lg align-middle" />
                  </span>
                </Link>
              </div>
            </div>
            <div className={`lg:w-1/4 w-1/2`}>
              <div className="br bgc-gray-50 py-1 my-p5 mx-p5 ta-center">
                <Link
                  to={{
                    pathname: path(routes.app.product.category, { category: 'sweatshirts' }),
                    search: '?filters[styles][0]=hoodies',
                  }}
                >
                  <img className="mb-p5" src="https://inkp-production.32pt.com/public/app/Hoodie.jpg" alt="Hoodies" />
                  <span className="mb-p5" style={sampleClassTitle}>
                    Hoodies
                  </span>
                  <span className="d-b" style={sampleClassSubTitle}>
                    Shop All <i className="d-ib mdi mdi-chevron-right fs-lg align-middle" />
                  </span>
                </Link>
              </div>
            </div>
            <div className={`lg:w-1/4 w-1/2`}>
              <div className="br bgc-gray-50 py-1 my-p5 mx-p5 ta-center">
                <Link
                  to={{
                    pathname: path(routes.app.product.category, { category: 't-shirts' }),
                    search: '?filters[styles][0]=sleeveless',
                  }}
                >
                  <img
                    className="mb-p5"
                    src="https://inkp-production.32pt.com/public/app/Tank-Top.jpg"
                    alt="Tank Tops"
                  />
                  <span className="mb-p5" style={sampleClassTitle}>
                    Tank Tops
                  </span>
                  <span className="d-b" style={sampleClassSubTitle}>
                    Shop All <i className="d-ib mdi mdi-chevron-right fs-lg align-middle" />
                  </span>
                </Link>
              </div>
            </div>
          </div>
          <div className="flex justify-center mt-2 lg:mt-3 mb-3 lg:mb-4">
            <Link
              to={path(routes.app.product.base)}
              className="br-full color-primary fs-mlg fw-bold py-1 px-2 bc-primary bw-1 bgc-primary-100:hover cursor-pointer"
            >
              Browse All Products
            </Link>
          </div>
        </div>
        {/* END CENTER Container with tags */}

        {/* START Desktop middle background */}
        <div className="d-n lg:d-b w-full bgc-blue-50 mb-3 pt-3" style={{ height: '690px' }}>
          <div className="lg:w-container mx-auto p-relative">
            <div
              className="d-n lg:d-b br p-absolute flex-none"
              style={{
                width: '465px',
                height: '543px',
                top: '50px',
                left: 0,
                backgroundImage: 'url(https://inkp-production.32pt.com/public/app/hf.jpg)',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: '50% 60%',
              }}
            ></div>
            <img
              className="d-n lg:d-b p-absolute"
              src="https://inkp-production.32pt.com/public/app/open_quotes%402x.png"
              alt="Open Quotes"
              style={{
                width: '170px',
                left: '29%',
                top: '4%',
              }}
            />
            <div className="d-n lg:d-b p-absolute" style={{ width: '72.4%', top: '15%', right: '-24%' }}>
              <div className="fs-2xl fw-extra-bold">But Don't Take Our Word for It</div>
              <div className="fs-xl fw-normal mt-1p5">
                Read real reviews from real customers <br />
                who are real happy.
              </div>
              <div className="h-16 mt-1p5 z-20" style={{ width: '76%', marginLeft: -33 }}>
                <CommentSlider
                  comments={COMMENTS}
                  width={495}
                  widthSlide={466}
                  heightSlide={268}
                  margin={3}
                  classSlides="ml-2"
                  id="desktop"
                />
              </div>
            </div>
          </div>
        </div>
        {/* END Desktop middle background */}

        {/* START Mobile middle background */}
        <div className="d-b lg:d-n w-full bgc-blue-50 mb-3">
          <div
            className="h-16 mt-1p5 w-full"
            style={{
              backgroundImage: 'url(https://inkp-production.32pt.com/public/app/mobile/high-five-mobile.jpg)',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
            }}
          ></div>
          <div className="px-1 pt-2 bgc-blue-50 ta-left">
            <span className="d-b color-navy fs-2xl fw-extra-bold">
              But Don’t Take Our <br />
              Word for It
            </span>
            <span className="d-b color-navy fs-xl fw-normal" style={{ lineHeight: '36px' }}>
              Read real reviews from real <br />
              customers who are real happy.
            </span>
          </div>
          <div className="pt-2 bgc-blue-50 mb-4 md:pb-p5">
            <CommentSlider comments={COMMENTS} id="mobile" />
          </div>
        </div>
        {/* END Mobile middle background */}

        {/* START bottom text */}
        <div className="lg:w-container lg:mb-2 mx-1 lg:mx-auto">
          <div className="fs-2xl fw-extra-bold mb-2 mt-2 lg:mt-3 lg:ta-center">
            Ordering Custom Shirts Should Be Easy & Inexpensive
          </div>
          <div className="flex flex-col lg:flex-row lg:-mx-p75">
            <div className="lg:w-1/3 lg:mx-p75 mb-2">
              <div className="fs-lg fw-bold mb-1">Simple, Competitive Prices</div>
              <p>
                Free shipping on all orders, no minimum quantities, and no setup fees… what more could you want? With
                Inkpop, the price you see is the price you’ll pay and there are no hidden fees or surprises. We are
                committed to providing simple, straightforward pricing that offers our customers the best pricing
                available for customized apparel - guaranteed.
              </p>
            </div>
            <div className="lg:w-1/3 lg:mx-p75 mb-2">
              <div className="fs-lg fw-bold mb-1">Impeccable Print Quality</div>
              <p>
                We’re veterans in the custom printed shirt business. With four printing facilities across the United
                States that have flawlessly printed millions of custom shirts, sweatshirts, and other gear, our printing
                facility reviews each design and draws from our experience and expertise to ensure the printed design
                will come out just the way you expect it to.
              </p>
            </div>
            <div className="lg:w-1/3 lg:mx-p75 mb-2">
              <div className="fs-lg fw-bold mb-1">Helpful Customer Service</div>
              <p>
                Our friendly support staff is available 5 days a week. Give us a call or send us an email - we’d love to
                hear from you! We can help you with your design or help you pick the right product to make your group
                look their best. Our art department reviews each and every design prior to printing in order to ensure
                the artwork quality and placement yield a customized print that meets both our standards and yours.
              </p>
            </div>
          </div>
        </div>
        <div className="lg:w-container mx-auto mb-3 lg:mb-4">
          <div className="flex justify-center">
            <Link
              to={routes.app.designTool}
              className="d-ib color-white br-full bgc-primary py-1 px-1p5 bgc-primary-600:hover"
              style={{ fontSize: '1.125rem', lineHeight: '1.75rem', fontWeight: 700 }}
            >
              Start Designing
            </Link>
          </div>
        </div>
        {/* END bottom text */}

        {showCOVID19Alert && (
          <Modal>
            <Alert
              alerts={[
                {
                  type: 'info',
                  title: 'COVID-19 closures impact',
                  content:
                    'Due to recent closures caused by COVID-19, we are unfortunately unable to process new orders until further notice. We will keep your cart saved and will notify you as soon as we can start processing orders again. Please accept our deepest apologies for any inconvenience this may cause :(',
                  onClose: this.onCloseCOVIDAlert(),
                },
              ]}
            ></Alert>
          </Modal>
        )}
      </div>
    );
  }
}
